<template>
  <div class="category-custom">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
    />

    <v-container>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_DESCRIPTION"
        tag="div"
      />
    </v-container>
  </div>
</template>
<style lang="scss">
.category-custom {
  .center-img {
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
<script>
// @ is an alias to /src
import categoryMixins from "~/mixins/category";

import get from "lodash/get";

export default {
  name: "CategoryCustom",
  mixins: [categoryMixins],
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: ".firstCategory",

          clickable: true
        }
      },
      count: -1
    };
  },
  components: {},
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];
      let path = this.breadcrumb;
      if (path && path[0].to === "/" && path.length > 1) {
        return path;
      }
      breadCrumbs.push({
        to: { name: "Home" },
        text: "Home",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Category",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      if (this.categoryId) {
        let subCategory = this.category.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.firstLevel.description", [
              this.category.name
            ])
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.category, "metaData.category_seo.SEO_KEYWORDS")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(
            this.category,
            "metaData.category_seo.SEO_TITLE",
            this.category ? this.category.name : ""
          )
        },
        {
          property: "og:type",
          content: "product.group"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          property: "og:image:alt",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.firstLevel.description", [
              this.category.name
            ])
          )
        },
        {
          property: "og:site_name",
          content: "Gala"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(
            this.category,
            "metaData.category_seo.SEO_TITLE",
            this.category ? this.category.name : ""
          )
        },
        {
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
  // async mounted() {

  // }
};
</script>
